import Cookies from 'js-cookie'
let TokenKey = 'Wx-H5-Auth-Token'
let UsernameKey = 'Wx-H5-Username'
let RolenameKey = 'Wx-H5-Rolename'
let ServernameKey = 'Wx-H5-Servername'
let UseridKey = 'Wx-H5-Userid'

/**
 * 得到用户登录后的 Token
 */
export function getToken(prefix="") {
  const TokenKeys = prefix + TokenKey
  return Cookies.get(TokenKeys)
}
/**
 * 设置 Token
 * @param {String} token
 */
export function setToken(token,prefix="") {
  if (!token) {
    return
  }
  const TokenKeys = prefix + TokenKey
  Cookies.set(TokenKeys, token)
}
/**
 * 删除登录用户的 Token
 */
export function removeToken(prefix="") {
  const TokenKeys = prefix + TokenKey
  Cookies.remove(TokenKeys)
}
export function removeUsername(prefix="") {
  const UsernameKeys = prefix + UsernameKey
  Cookies.remove(UsernameKeys)
}
export function setUsername(username,prefix="") {
  const UsernameKeys = prefix + UsernameKey
  console.log(UsernameKeys)
  Cookies.set(UsernameKeys, username)
}
export function getUsername(prefix="") {
  const UsernameKeys = prefix + UsernameKey
  return Cookies.get(UsernameKeys)
}
export function setRolename(rolename) {
  return Cookies.set(RolenameKey,rolename)
}
export function getRolename() {
  return Cookies.get(RolenameKey)
}
export function removeRolename() {
  Cookies.remove(RolenameKey)
}
export function setServername(server) {
  return Cookies.set(ServernameKey,server)
}
export function getServername() {
  return Cookies.get(ServernameKey)
}
export function removeServername() {
  Cookies.remove(ServernameKey)
}
export function setUserid(userid) {
  return Cookies.set(UseridKey,userid)
}

export function getUserid() {
  return Cookies.get(UseridKey)
}
export function removeUserid() {
  Cookies.remove(UseridKey)
}

export function checkAuth() {
  return !!getToken()
}
